<template>
  <div class="text-center mt-4">
    <Subscribe
      :country-code="countryCode"
      :email="email"
      @completed="completed"
    />
  </div>
</template>

<script>
import Subscribe from '@/modules/billwerk/subscribe'
import COMPLETE_ONBOARDING_STEP_BILLING from './queries/CompleteOnboardingStepBilling.gql'
import bus, { eventNames } from '@/lib/eventBus'

export default {
  components: {
    Subscribe
  },
  props: {
    countryCode: {
      type: String,
      default: 'de'
    }
  },
  computed: {
    email () {
      return this.$auth.user.email
    }
  },
  methods: {
    async completed () {
      await this.completeBillingStep()
      this.$emit('completed')
    },
    async completeBillingStep () {
      try {
        await this.$apollo.mutate({
          mutation: COMPLETE_ONBOARDING_STEP_BILLING
        })
      } catch (err) {
        bus.$emit(
          eventNames.SHOW_SNACKBAR,
          {
            color: 'error',
            text: 'Es gab leider ein technisches Problem beim speichern Ihrer Daten. Wir kümmern uns darum!'
          }
        )
      }
    }
  }

}
</script>
