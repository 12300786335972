<template>
  <div class="text-center mt-4 px-4 px-md-0">
    <h1 class="display-1 font-weight-black mb-4">
      Hallo {{ $auth.user.name }}!
    </h1>
    <p class="text-body-2 mb-8">
      Schön Sie bei uns begrüßen zu dürfen! Um Ihr BOTTIMMO individuell einzurichten, benötigen wir nur wenige Angaben:
    </p>

    <v-row class="ma-0 pa-0">
      <v-select
        v-model="countryCode"
        outlined
        label="Land Ihres Firmensitzes"
        :items="countries"
      />
    </v-row>

    <LinkData
      v-bind="{links}"
      :trigger-validation="triggerLinkValidation"
      @updateHasNoInitialWebsite="updateHasNoInitialWebsite"
      @resetTriggerValidation="setTriggerLinkValidation(false)"
      @updateSettings="updateLinks"
      @validated="submit"
    />

    <v-btn
      class="w-full primary mb-2 mt-4"
      :disabled="!canSubmit"
      @click="setTriggerLinkValidation(true)"
    >
      {{ $t('buttons.signup.try-free') }}
    </v-btn>
    <RemaxInfoDialog
      :is-de-remax-customer="isDeRemaxCustomer"
      @accept="reset"
    />
  </div>
</template>

<script>
import LinkData from '@/modules/registration/LinkData.vue'
import brandingMixin from '@/mixins/branding'
import { COUNTRIES } from '@/components/forms/labels'
import COMPLETE_ONBOARDING_STEP_WEBSITE from './queries/CompleteOnboardingStepWebsite.gql'
import bus, { eventNames } from '@/lib/eventBus'
import RemaxInfoDialog from '../auth/RemaxInfoDialog.vue'
import { formatHttpsUrl } from '@/lib/urlFormatter'
import { isValidUrl } from '@/lib/validation'

export default {
  components: {
    LinkData,
    RemaxInfoDialog
  },
  mixins: [brandingMixin],

  data () {
    return {
      links: {
        website: '',
        privacy: '',
        imprint: ''
      },
      hasNoInitialWebsite: false,
      countryCode: 'DE',
      countries: COUNTRIES,
      triggerLinkValidation: false
    }
  },
  computed: {
    isDeRemaxCustomer () {
      return this.countryCode === 'DE' && Boolean(
        this.links.website.match(/remax/i) ||
        this.links.privacy.match(/remax/i) ||
        this.links.imprint.match(/remax/i))
    },
    canSubmit () {
      return this.hasNoInitialWebsite || Object.keys(this.links).every(key => isValidUrl(this.links[key]))
    }
  },
  created () {
    const { locale = 'de-DE' } = this.$auth.user
    this.countryCode = locale.split('-')[1]
  },
  methods: {
    reset () {
      this.links = {
        website: '',
        privacy: '',
        imprint: ''
      }
    },
    updateLinks (links) {
      this.links = { ...links }
    },
    updateHasNoInitialWebsite (hasNoInitialWebsite) {
      this.hasNoInitialWebsite = hasNoInitialWebsite
    },
    setTriggerLinkValidation (trigger) {
      this.triggerLinkValidation = trigger
    },

    async submit () {
      if (!this.hasNoInitialWebsite) {
        Object.keys(this.links).forEach(key => {
          this.links[key] = formatHttpsUrl(this.links[key])
        })
      }

      try {
        const { data: { completeOnboardingStepWebsite } } = await this.$apollo.mutate({
          mutation: COMPLETE_ONBOARDING_STEP_WEBSITE,
          variables: {
            input: {
              hasNoInitialWebsite: this.hasNoInitialWebsite,
              links: this.links,
              countryCode: this.countryCode.toLowerCase()
            }
          }
        })
        if (completeOnboardingStepWebsite.status === 'SUCCESS') {
          this.$emit('completed', this.countryCode.toLowerCase())
        }

        if (completeOnboardingStepWebsite.status === 'ERROR') {
          if (completeOnboardingStepWebsite.errorCode === 'INVALID_WEBSITE_LINKS') {
            bus.$emit(
              eventNames.SHOW_SNACKBAR,
              {
                color: 'error',
                text: 'Mindestens einer der angegebenen Links ist nicht gültig. Bitte überprüfen Sie Ihre Eingaben.'
              }
            )
          } else {
            bus.$emit(
              eventNames.SHOW_SNACKBAR,
              {
                color: 'error',
                text: 'Es gab leider ein technisches Problem beim speichern Ihrer Daten. Wir kümmern uns darum!'
              }
            )
          }
        }
      } catch (error) {
        bus.$emit(
          eventNames.SHOW_SNACKBAR,
          {
            color: 'error',
            text: 'Es gab leider ein technisches Problem beim speichern Ihrer Daten. Wir kümmern uns darum!'
          }
        )
      }
    }
  }

}
</script>
