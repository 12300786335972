<template>
  <div
    class="justify-center w-full h-full d-flex align-center login-hero"
    style="background: white"
  >
    <v-img
      alt="Company Logo"
      :class="{'login-logo':$vuetify.breakpoint.mdAndUp, 'login-logo-mobile': $vuetify.breakpoint.smAndDown}"
      :src="require('@/../public/img/logo.png')"
      width="140"
      :style="{'zIndex': 100}"
      contain
    />

    <div
      v-if="company && activeStep"
      style="z-index: 2"
      class="h-full d-flex flex-column justify-space-between align-center registration-container"
    >
      <v-stepper
        v-if="isSelfOnboarding"
        v-model="step"
        non-linear
        alt-labels
        elevation="0"
        class="h-full mt-16 stepper d-flex flex-column justify-space-between align-center mt-md-0"
      >
        <v-stepper-header
          class="flex-nowrap flat"
          style="width: 400px"
        >
          <template
            v-for="({title}, index) in steps"
          >
            <v-stepper-step
              :key="title"
              :complete="step>index+1"
              class="stepper-head"
              edit-icon="$complete"
              :step="index + 1"
            >
              {{ title }}
            </v-stepper-step>
            <v-divider
              v-if="index !== steps.length - 1"
              :key="index"
            />
          </template>
        </v-stepper-header>

        <v-stepper-items class="justify-center d-flex stepper-items align-center">
          <v-stepper-content
            class="stepper-content"
            step="1"
          >
            <Website
              v-if="activeStep === 'WEBSITE'"
              :style="{'width': $vuetify.breakpoint.smAndDown ? '100%':'450px'}"
              @completed="submitWebsite"
            />
          </v-stepper-content>

          <v-stepper-content
            class="stepper-content align-left"
            step="2"
          >
            <CRMConfig
              v-if="hasFreeSignUp && activeStep === 'CRM_CONFIG'"
              @completed="submitCrmConfig"
            />
            <Billing
              v-if="!hasFreeSignUp && activeStep === 'BILLING'"
              :style="{'width': $vuetify.breakpoint.smAndDown ? '100%':'1300px'}"
              :country-code="countryCode"
              @completed="submitBilling"
            />

            <v-row
              v-if="hasFreeSignUp && activeStep === 'FINISHED'"
              class="px-4 pd-md-0"
              :style="{'width': $vuetify.breakpoint.smAndDown ? '100%':'830px'}"
            >
              <Loading
                @completed="submitBilling"
              />
            </v-row>

            <Error
              v-if="activeStep==='ERROR'"
              style="width: 400px"
            />
          </v-stepper-content>
        </v-stepper-items>

        <!-- AGB and Legal -->
        <div class="justify-center mt-8 mb-4 d-flex flex-column flex-md-row align-center">
          <template v-if="useIubenda">
            <IubendaDocumentLink type="privacyPolicy" />
            <IubendaConsentLink class="ml-4" />
            <IubendaDocumentLink
              type="terms"
              class="mx-4"
            />
          </template>
          <template v-else>
            <a
              :href="publicDataPrivacyUrl"
              target="_blank"
              class="my-2 my-md-0"
            >
              {{ $t('legal.topic.privacy.title') }}
            </a>
            <span class="mx-2 d-none d-md-block">|</span>
            <Feature
              v-slot="{feature}"
              :feature-slug="featureNames.DATA_PRIVACY"
            >
              <div
                v-if="feature.config.hasConditions"
                class="d-flex flex-column flex-md-row "
              >
                <a
                  :href="feature.config.conditionsUrl"
                  target="_blank"
                  class="my-2 my-md-0"
                >
                  Nutzungs- und Lizenzbedingungen
                </a>
                <span class="mx-2 d-none d-md-block">|</span>
              </div>
            </Feature>
          </template>
          <a
            :href="imprintUrl"
            target="_blank"
            class="my-2 my-md-0"
          >
            Impressum
          </a>
        </div>
      </v-stepper>

      <div
        v-else
        style="width:300px"
        class="justify-center d-flex flex-column"
      >
        <v-alert
          class="mt-4"
          icon="mdi-check"
          outlined
        >
          Sie haben die Registrierung erfolgreich abgeschlossen.
        </v-alert>
        <v-btn
          to="/dashboard"
          color="primary"
        >
          Weiter zur App
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { iubenda } from '@/lib/features'
import brandingMixin from '@/mixins/branding'
import Website from './Website.vue'
import Loading from './Loading.vue'
import CRMConfig from './CRMConfig.vue'
import Error from './Error.vue'
import Billing from './Billing.vue'
import featureMixin from '@/mixins/feature'
import COMPANY_BASE from '@/queries/CompanyBase.gql'
import GET_CURRENT_ONBOARDING_STEP from './queries/GetCurrentOnboardingStep.gql'
import IubendaDocumentLink from '@/modules/iubenda/DocumentLink.vue'
import IubendaConsentLink from '@/modules/iubenda/ConsentPreferencesLink.vue'

const stepperToStep = {
  WEBSITE: 1,
  CRM_CONFIG: 2,
  BILLING: 2,
  FINISHED: 2
}
export default {
  components: {
    Website,
    CRMConfig,
    Loading,
    Billing,
    Error,
    IubendaDocumentLink,
    IubendaConsentLink
  },
  mixins: [featureMixin, brandingMixin],
  data () {
    return {
      step: 1,
      activeStep: null,
      countryCode: 'de',
      useIubenda: iubenda?.isActive
    }
  },
  computed: {
    hasFreeSignUp () {
      return this.company?.hasFreeSignUp
    },
    isSelfOnboarding () {
      return this.company?.state?.stringValues?.includes('SELF_ONBOARDING')
    },
    steps () {
      const steps = [
        { title: 'Konfiguration' },
        { title: this.hasFreeSignUp ? 'Einrichtung' : 'Rechnungsdaten' }
      ]
      return steps.map((step, index) => ({ ...step, step: index + 1 }))
    }
  },
  watch: {
    activeStep () {
      this.step = stepperToStep[this.activeStep]
    }
  },
  methods: {
    submitWebsite (countryCode) {
      this.countryCode = countryCode
      if (!this.hasFreeSignUp) {
        this.fetchCurrentOnboardingStep()
      } else {
        this.activeStep = 'CRM_CONFIG'
      }
    },
    async submitBilling () {
      this.$router.push('/dashboard')
    },
    async fetchCurrentOnboardingStep () {
      this.$apollo.queries.getCurrentOnboardingStep.refetch()
    },
    async submitCrmConfig () {
      this.activeStep = 'FINISHED'
    }
  },
  apollo: {
    company: {
      query: COMPANY_BASE,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      },
      skip () {
        return this.$auth.loading || !this.$auth.isAuthenticated || !this.$auth.user.companyId
      }
    },
    getCurrentOnboardingStep: {
      query: GET_CURRENT_ONBOARDING_STEP,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      },
      skip () {
        return this.$auth.loading || !this.$auth.isAuthenticated || !this.$auth.user.companyId
      },
      update ({ getCurrentOnboardingStep }) {
        this.activeStep = getCurrentOnboardingStep.name
      }
    }
  }
}
</script>

<style scoped>
.login-logo{
  position: absolute;
  top:0;
  left:0;
  margin-top:30px;
  margin-left:30px;
  z-index: 2;
}

.login-logo-mobile{
  position: absolute;
  top:0;
  margin-top:30px;
  left:auto;
  right:auto;
  z-index: 2;
}

.registration-container{
  overflow: scroll;
  overflow-x: hidden;
}

.flat{
  box-shadow: none;
  -webkit-box-shadow: none;
}

.login-hero{
  position: relative;
  height: 100%;
}

.login-logo{
  position: absolute;
  top:0;
  left:0;
  margin-top:30px;
  margin-left:30px;
  z-index: 2;
}

.stepper-content{
  min-width: 300px;
  max-width: 100%;
  padding: 0px;
  display:flex;
  justify-content: center;
}
</style>

<style>
@media only screen and (max-width: 959.98px) {
  .registration-container > .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display:blocK !important
  }
}
</style>
